<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="tabs-page container">
      <b-tabs pills>
        <b-tab title="Business Info" @click="$navigateTo('lead-center-settings-platform')" :active="$route.name === 'lead-center-settings-platform'">
          <lead-center-additional-settings />
        </b-tab>
        <b-tab title="Data Extractor" @click="$navigateTo('lead-center-data-extractor')" :active="$route.name === 'lead-center-data-extractor'">
          <lead-center-extractor type="data" />
        </b-tab>
        <b-tab title="Lead Extractor" @click="$navigateTo('lead-center-lead-extractor')" :active="$route.name === 'lead-center-lead-extractor'">
          <lead-center-extractor type="lead" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import LeadCenterAdditionalSettings from '@/agGridV2/components/lead-center-additional-settings.vue'
import LeadCenterExtractor from '@/agGridV2/components/lead-center-extractor.vue'

// Components
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'

export default {
  data() {
    return {}
  },
  components: {
    LeadCenterAdditionalSettings,
    LeadCenterNavigation,
    LeadCenterExtractor
  }
}
</script>

<style lang="scss">
</style>
