<template>
  <div class="additional_settings">
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="info">Info:</label>
            <b-input
              id="info"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="info"
              @change="editSettings('info', info)"
            ></b-input>
            <b-form-text id="company_info">Please fill in the company name and type. E.g. "Barr & Douds law firm".</b-form-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="services">Services:</label>
            <b-input
              id="services"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="services"
              @change="editSettings('services', services)"
            ></b-input>
            <b-form-text id="company_services">List them separated by commas</b-form-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="services_exceptions">Services Exceptions:</label>
            <b-input
              id="services_exceptions"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="services_exceptions"
              @change="editSettings('services_exceptions', services_exceptions)"
            ></b-input>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="locations">Locations:</label>
            <b-input
              id="locations"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="locations"
              @change="editSettings('locations', locations)"
            ></b-input>
            <b-form-text id="company_locations">Please fill in the main company Locations. List them separated by commas</b-form-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="locations_exceptions">Locations Exceptions:</label>
            <b-input
              id="locations_exceptions"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="locations_exceptions"
              @change="editSettings('locations_exceptions', locations_exceptions)"
            ></b-input>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="old_kpi">Old kpi:</label>
            <b-input
              id="locations"
              class="border-0 border-bottom rounded-0 bg-light w-100 p-2"
              v-model="old_kpi"
              @change="editSettings('old_kpi', old_kpi)"
            ></b-input>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row class="pb-2" style="display: flex; align-items: end">
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="average_check">Average Check:</label>
            <b-input-group>
              <b-form-input
                class="p-2 bg-light"
                placeholder="$"
                v-model.number="average_check"
                @change="editSettings('average_check', average_check)"
                type="number">
              </b-form-input>
              <b-input-group-append>
                <span class="input-group-text" style="border: none">$</span>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="closing_rate_mql_client">Closing rate MQL->CLIENT:</label>
            <b-input-group>
              <b-form-input
                class="p-2 bg-light"
                placeholder="%"
                v-model.number="closing_rate_mql_client"
                @change="validateRate('closing_rate_mql_client')"
                type="number">
              </b-form-input>
              <b-input-group-append>
                <span class="input-group-text" style="border: none">%</span>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="closing_rate_conversion_client">Closing rate CONVERSION->CLIENT:</label>
            <b-input-group>
              <b-form-input
                class="p-2 bg-light"
                placeholder="%"
                v-model.number="closing_rate_conversion_client"
                @change="validateRate('closing_rate_conversion_client')"
                type="number">
              </b-form-input>
              <b-input-group-append>
                <span class="input-group-text" style="border: none">%</span>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-3 bg-white rounded position-relative">
      <b-row class="pb-2" style="display: flex; align-items: end">
        <b-col>
          <div role="group" class="mt-2 mb-2">
            <label class="h5" for="automatic_lead_qualification_start">Automatic lead qualification start:</label>
            <div style="display: flex; justify-content: space-between; gap: 20px;">
              <div style="flex: 1">
                <custom-select
                  :value="automatic_lead_qualification_start"
                  :options="makePeriod(false, true)"
                  :datepicker="true"
                  @input="changeLeadQualificationStart"
                ></custom-select>
              </div>
              <b-btn
                style="min-height: 44px;"
                variant="primary"
                class="border-0 border-bottom rounded-0"
                @click="changeLeadQualificationStart('')"
              >Reset</b-btn>
            </div>
          </div>
        </b-col>
        <b-col>

        </b-col>
        <b-col>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { monthYear, yearMonthDay } from '@/helpers/dateFormats'
import dayjs from 'dayjs'
import { isArray, isString } from 'lodash'
import { mapGetters, mapMutations } from "vuex";
import CustomSelect from '@/components/ui/CustomSelect'

export default {
  name: 'lead-center-additional-settings',
  components: {
    CustomSelect
  },
  data() {
    return {
      locations: null,
      locations_exceptions: null,
      old_kpi: null,
      services: null,
      services_exceptions: null,
      average_check: null,
      closing_rate_mql_client: null,
      closing_rate_conversion_client: null,
      info: null,
      automatic_lead_qualification_start: null,
      changed_services: false
    }
  },
  computed: {
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  watch: {
    selectedPlatform() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.selectedPlatform._id === 'all') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$router.push({ name: 'lead-center-activities' })
        return false
      }
      (async () => {
        this.setViewLoader(true)
        const settings = await MtApi.getPlatform(this.selectedPlatform._id, { select: 'services services_exceptions closing_rate_mql_client average_check closing_rate_conversion_client locations locations_exceptions info automatic_lead_qualification_start old_kpi' })
        this.services = settings?.services
        this.services_exceptions = settings?.services_exceptions
        this.average_check = settings?.average_check
        this.closing_rate_mql_client = settings?.closing_rate_mql_client
        this.closing_rate_conversion_client = settings?.closing_rate_conversion_client
        this.locations = settings?.locations
        this.locations_exceptions = settings?.locations_exceptions
        this.old_kpi = settings?.old_kpi
        this.info = settings?.info
        if (settings?.automatic_lead_qualification_start) {
          const currentDate = dayjs(settings?.automatic_lead_qualification_start)
          this.automatic_lead_qualification_start = {
            label: currentDate.format(monthYear),
            code: currentDate.format(yearMonthDay)
          }
        } else {
          this.automatic_lead_qualification_start = null
        }
        this.setViewLoader(false)
      })()
    },
    isArray,
    ...mapGetters([
      'getSelectedPlatform',
      'getInitDateRange'
    ]),
    ...mapMutations([
      'setViewLoader',
    ]),
    async editSettings(name, value) {
      this.setViewLoader(true)
      await MtApi.updatePlatform(this.selectedPlatform._id, {
        event: 'update_platform',
        field: {
          name,
          value
        }
      })
      this.$bvToast.toast('Successfully Saved', {
        title: 'Settings Saved',
        variant: 'success',
        solid: true
      })
      if (name === 'services') {
        this.changed_services = false
      }
      this.setViewLoader(false)
    },
    validateRate(field) {
      let newValue = this[field]
      if (newValue < 0) {
        newValue = 0
      } else if (newValue > 100) {
        newValue = 100
      }
      this[field] = newValue
      this.editSettings(field, newValue)
    },
    makePeriod(end = false, next = false) {
      const from = dayjs(dayjs(this.getInitDateRange().start).startOf('month').format(yearMonthDay))
      let to = dayjs(new Date())
      if (next) {
        to = dayjs(new Date()).add(1, 'month')
      }
      let currentDate = from
      const period = []
      while (currentDate.isBefore(to) || (end ? currentDate.isSame(to, 'month') : false)) {
        let dateOption
        if (end) {
          const lastDayOfMonth = currentDate.endOf('month')
          dateOption = {
            label: lastDayOfMonth.format(monthYear),
            code: lastDayOfMonth.format(yearMonthDay)
          }
        } else {
          dateOption = {
            label: currentDate.format(monthYear),
            code: currentDate.format(yearMonthDay)
          }
        }
        period.push(dateOption)
        currentDate = currentDate.add(1, 'month')
      }
      period.sort((a, b) => dayjs(b.code).isAfter(dayjs(a.code)) ? 1 : -1);

      return period
    },
    changeLeadQualificationStart(value) {
      this.$dialog
        .confirm(`<img style="width: 100%; margin-bottom: 20px;" src="/uncle_sam.webp" />Are you sure you want to change the automatic lead collection date? It will influence the KPI table!`, {
          html: true,
          cancelText: 'Close',
          okText: 'I\'m sure',
          clicksCount: 3,
          backdropClose: true,
          type: 'soft'
        })
        .then(async(dialog) => {
          this.automatic_lead_qualification_start = value ? value : { label: '', code: '' }
          this.editSettings('automatic_lead_qualification_start', value?.code || value)
        })
    }
  }
}
</script>
